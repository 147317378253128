import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  SyntheticEvent,
} from "react";
import { Button, Dropdown, Form, Icon, Label, List, Modal, Popup } from "semantic-ui-react";

import htmlParse from "html-react-parser";

// UI ADM
import CardAdmitChange from "./CardAdmitChange";
import CardWardQueueUX from "./CardWardQueueUX";
import ModCancelAdmitOrder from "./ModCancelAdmitOrder";
import CardAddCoPhysician from "./CardAddCoPhysician";

// UI PTM
import CardIntraHospitalTransfer from "../PTM/CardIntraHospitalTransfer";

// UI Common
import SearchBox from "react-lib/appcon/common/SearchBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ModInfo from "react-lib/apps/common/ModInfo";
import { useIntl } from "react-intl";

// Utils

// Constance
const CARD_QUEUE_WARD = "CardWardQueue";
const CARD_RESERVE_BED = "CardReserveBed";

const ADMIT_ORDER_ROOM_ITEM_ACTION = {
  REVERSE_ADMIT: "REVERSE_ADMIT",
  REVERSE_WARD: "REVERSE_WARD",
  TRANSFER: "TRANSFER",
  CANCEL: "CANCEL",
  REVERSE_TRANSFER: "REVERSE_TRANSFER",
  CHANGE_BED: "CHANGE_BED",
  WARD: "WARD",
};

const CardWardQueue = (props: any) => {
  const intl = useIntl();
  const [openConfirm, setOpenConfirm] = useState<{
    type: "cancel_admit";
    title: string;
  } | null>(null);
  const [openModBedMove, setOpenModBedMove] = useState<boolean>(false);
  const [modAddCoPhysician, setModAddCoPhysician] = useState<any>({
    open: false,
    encounter: null,
    primary_doctor: "",
    co_physician: [],
  });
  const [selectedHN, setSelectedHN] = useState<string>("");

  const barcodeRef: any = useRef();
  const touchCountRef = useRef(0);
  const touchTimerRef = useRef<any>(null);

  useEffect(() => {
    props.runSequence({
      sequence: "WardQueue",
      card: CARD_QUEUE_WARD,
      isReport: !!props.isReport,
    });

    // #if (props.isReport) {
    //   console.log('isReport: ', props.isReport);
    //  let statusValue = props.masterOptions.patientIPDStatus?.find((item: any) => item.text === "Ward Admitted")?.value
    //  console.log('statusValue: ', statusValue);
    //  if (statusValue !== undefined) {
    //    props.setProp("WardQueueSequence.filterQueue.status", statusValue)
    //  }
    // }
    return () => {
      const clear = () => {
        props.setProp(`errorMessage.${CARD_QUEUE_WARD}`, null);

        props.setProp(`successMessage.${CARD_QUEUE_WARD}`, null);

        props.runSequence({ sequence: "WardQueue", clear: true });
      };

      clear();
    };
  }, []);

  useEffect(() => {
    if (barcodeRef.current) {
      barcodeRef.current.inputRef.current.value = "";
    }

    props.runSequence({ sequence: "WardQueue", restart: true });
  }, [props.division]);

  useEffect(() => {
    if (props.buttonLoadCheck?.CardAdmitChange === "SUCCESS") {
      handleCloseModBedMove();
      props.runSequence({
        sequence: "WardQueue",
        action: "search",
        card: CARD_QUEUE_WARD,
      });
    }
  }, [props.buttonLoadCheck?.CardAdmitChange]);

  const handleOpenPopup = useCallback(
    (item: any, index: number) => {
      if (item) {
        const selected = props.WardQueueSequence?.selectedAdmitRoomItem;
        const hns: any[] = item?.hn.split(" ");
        const data = props.WardQueueSequence?.wardQueueList?.[index];

        setSelectedHN(hns[0]);

        props.runSequence({
          sequence: "WardQueue",
          action: "select_admit_room",
          admitRoom:
            selected?.index === index
              ? null
              : {
                  ...data,
                  index,
                  show: true,
                },
          card: CARD_QUEUE_WARD,
        });
      }
    },
    [
      props.WardQueueSequence?.wardQueueList,
      props.WardQueueSequence?.selectedAdmitRoomItem,
    ]
  );

  const handleClosePopup = useCallback(() => {
    setTimeout(() => {
      props.setProp("WardQueueSequence.selectedAdmitRoomItem", null);
    });
  }, []);

  const getEnabledAction = useCallback(
    (action: string) => {
      return (
        props.WardQueueSequence?.permission?.[
          `action_ADM_AdmitOrderRoomItem_${action}`
        ] &&
        props.WardQueueSequence?.selectedAdmitRoomItem?.allowed_actions?.includes(
          action
        )
      );
    },
    [props.WardQueueSequence]
  );

  const handleAdmitReceive = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation();

      await props.setProp("WardQueueSequence.selectedAdmitRoomItem", {
        ...props.WardQueueSequence.selectedAdmitRoomItem,
        show: false,
      });

      props.runSequence({
        sequence: "WardQueue",
        action: "open_receive_admit",
        card: CARD_QUEUE_WARD,
      });
    },
    [props.WardQueueSequence?.selectedAdmitRoomItem]
  );

  const handleOpenModBedMove = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      props.setProp("WardQueueSequence.selectedAdmitRoomItem", {
        ...props.WardQueueSequence.selectedAdmitRoomItem,
        show: false,
      });

      setOpenModBedMove(true);
    },
    [props.WardQueueSequence?.selectedAdmitRoomItem]
  );

  const handleCancelAdmit = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation();

      await props.setProp("WardQueueSequence.selectedAdmitRoomItem", {
        ...props.WardQueueSequence.selectedAdmitRoomItem,
        show: false,
      });

      setOpenConfirm({
        type: "cancel_admit",
        title: "ต้องการยกเลิก Admit Order หรือไม่",
      });
    },
    [props.WardQueueSequence?.selectedAdmitRoomItem]
  );

  const handleOpenModMoveOut = useCallback(
    async (e: any) => {
      e.stopPropagation();

      await props.setProp("WardQueueSequence.selectedAdmitRoomItem", {
        ...props.WardQueueSequence.selectedAdmitRoomItem,
        show: false,
      });

      await props.setProp("WardQueueSequence.moveOut.open", true);
    },
    [props.WardQueueSequence?.selectedAdmitRoomItem]
  );

  const renderWardQueueList = useMemo(() => {
    return (props.WardQueueSequence?.wardQueueList || []).map((item: any, index: number) => ({
      ...item,
      patient_info: item.patient_name ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <span>{item.patient_name}</span>
          <span>{item.age}</span>
          <span>{`(${item.birthdate})`}</span>
        </div>
      ) : (
        ""
      ),
      patient_status: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <span>{item.room_item_status_label}</span>
          <span style={{ color: "red" }}>{item.status_date}</span>
        </div>
      ),
      primary_doctor_text: (
        <>
          {item.primary_doctor || ""}
          
          {item?.co_physician?.length > 0 && (
            <Popup
              content={
                <>
                  <List divided verticalAlign="middle">
                    {item.co_physician?.map((item: any, index: number) => {
                      return (
                        <List.Item key={index} style={{padding: "10px 5px"}}>                            
                          <List.Content>{item?.full_name || ""}</List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </>
              }
              trigger={
                <div style={{ color: "grey" }}>
                  +<Icon name="user" />
                  {item?.co_physician?.length}
                </div>
              }
              position="right center"
            />
          )}
        </>
      ),
      diagnosis: htmlParse(item.diagnosis),
      hn: (
        <div id={`CardWardQueue-div-${item.hn || index}`}>
          {item.hn ? `${item.hn} [${item.encounter?.number}]` : ""}
        </div>
      ),
      new_order: (
        <div style={{ display: "grid", placeContent: "center" }}>
          {!!item?.new_orders && <Label color="red">new order</Label>}
        </div>
      ),
      menu: (
        <Popup
          open={
            props.WardQueueSequence?.selectedAdmitRoomItem?.index === index &&
            props.WardQueueSequence?.selectedAdmitRoomItem?.show
          }
          on="click"
          position="bottom right"
          onClose={() => handleClosePopup()}
          content={
            <div style={{ display: "grid", rowGap: "10px" }}>
              <Button
                color="orange"
                disabled={!getEnabledAction(ADMIT_ORDER_ROOM_ITEM_ACTION.WARD)}
                onClick={handleAdmitReceive}
              >
                รับเข้า
              </Button>
              <Button
                color="green"
                disabled={!getEnabledAction(ADMIT_ORDER_ROOM_ITEM_ACTION.CHANGE_BED)}
                onClick={handleOpenModBedMove}
              >
                ย้ายเตียง
              </Button>
              {getEnabledAction(ADMIT_ORDER_ROOM_ITEM_ACTION.TRANSFER) && (
                <Button color="green" onClick={handleOpenModMoveOut}>
                  ย้ายออก
                </Button>
              )}

              <Button
                color="red"
                disabled={!getEnabledAction(ADMIT_ORDER_ROOM_ITEM_ACTION.REVERSE_WARD)}
                onClick={handleCancelAdmit}
              >
                ยกเลิกรับเข้า
              </Button>
              <Button
                color="blue"
                onClick={() => {
                  setModAddCoPhysician({
                    open: true,
                    encounter: item?.encounter,
                    primary_doctor: item?.primary_doctor || "",
                    co_physician: item?.co_physician || [],
                  });
                }}
              >
                เพิ่มแพทย์ผู้ร่วมรักษา
              </Button>
            </div>
          }
          trigger={
            <div style={{ display: "grid", placeContent: "center" }}>
              <Button
                icon="list layout"
                size="mini"
                onDoubleClick={(e: any) => e.stopPropagation()}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleOpenPopup(item, index);
                }}
              />
            </div>
          }
        />
      ),
    }));
  }, [
    props.WardQueueSequence?.wardQueueList,
    props.WardQueueSequence?.selectedAdmitRoomItem,
  ]);

  const handleCloseModAddCoPhysician = () => {
    setModAddCoPhysician({
      open: false,
      encounter: null,
      primary_doctor: "",
      co_physician: [],
    });
  };

  const handleRefreshCloseModAddCoPhysician = () =>{
    handleCloseModAddCoPhysician();
    handleSearch();
  };

  const handleCloseModAdmit = () => {
    props.setProp("WardQueueSequence.sequenceIndex", "FilterQueue");

    props.setProp("modAdmit.open", false);

    props.setProp("selectedEncounter", null);
  };

  const handleCloseModConfirm = () => {
    setOpenConfirm(null);
    handleClosePopup();

    props.setProp(`errorMessage.${CARD_RESERVE_BED}`, null);
  };

  const handleConfirm = (note: string) => {
    if (openConfirm?.type === "cancel_admit") {
      props.runSequence({
        sequence: "WardQueue",
        action: "cancel_admit",
        card: CARD_RESERVE_BED,
        note,
      });
    }
  };

  const handleBarcodeKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (barcodeRef?.current) {
        // props.setProp(
        //   "WardQueueSequence.filterQueue.barcode",
        //   barcodeRef.current.inputRef.current.value
        // );
        props.runSequence({
          sequence: "WardQueue",
          action: "barcode_search",
          barcode: barcodeRef.current.inputRef.current.value,
          card: CARD_QUEUE_WARD,
        });
      }
    }
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "WardQueue",
      action: "search",
      card: CARD_QUEUE_WARD,
    });
  };

  const handleCloseModBedMove = () => {
    setOpenModBedMove(false);

    handleClosePopup();
  };

  const handleGetRoomList = (params: any) => {
    return props.onEvent({ message: "GetListRoom", params });
  };

  const handleCloseMoveOut = () => {
    props.setProp("WardQueueSequence.moveOut", {
      open: false,
      cleaningChoise: null,
      error: null,
    });
  };

  const handlePrintReport = () => {
    if (props.WardQueueSequence?.wardQueueList?.length === 0) {
      props.runSequence({
        sequence: "WardQueue",
        action: "search",
        card: CARD_QUEUE_WARD,
        withPrint: true,
        start: props.WardQueueSequence?.filterQueue?.start || "-",
        end: props.WardQueueSequence?.filterQueue?.end || "-",
        division: props.WardQueueSequence?.filterQueue?.division,
      });
    } else {
      props.runSequence({
        sequence: "WardQueue",
        action: "printWardQueueList",
        start: props.WardQueueSequence?.filterQueue?.start || "-",
        end: props.WardQueueSequence?.filterQueue?.end || "-",
        division: props.WardQueueSequence?.filterQueue?.division,
        // (props.masterOptions?.division || []).find(
        //   (item: any) =>
        //     item.value === props.WardQueueSequence?.filterQueue?.division
        // )?.text,
      });
    }
  };

  const handleChangeStartDate = (date: any) => {
    let wqs = {
      ...props.WardQueueSequence,
      filterQueue: {
        ...props.WardQueueSequence?.filterQueue,
        start: date,
      },
      // wardQueueList: [],
    };

    props.setProp("WardQueueSequence", wqs);
    // props.setProp("WardQueueSequence.filterQueue.start", date);
  };

  const handleSelectRow = (rowInfo: any) => {
    const selected = props.WardQueueSequence.selectedAdmitRoomItem;
    const { encounter } = rowInfo?.original || {};

    if (selected?.show) {
      return;
    }

    if (encounter) {
      props.onEvent({
        message: "HandleSelectAdmitEncounter",
        params: {
          encounterId: encounter.id || null,
          admitRoom: rowInfo?.original,
          card: CARD_QUEUE_WARD,
        },
      });
      props?.forward();
    }
  };

  const handleCloseModNotFound = () => {
    props.setProp(`errorMessage.${CARD_QUEUE_WARD}_barcode`, null);
  };

  // -const handleTouchStart = (rowInfo: any) => {
  //   touchCountRef.current++;
  //   if (touchCountRef.current === 1) {
  //     touchTimerRef.current = setTimeout(function () {
  //       touchCountRef.current = 0;
  //     }, 300);
  //   } else if (touchCountRef.current === 2) {
  //     clearTimeout(touchTimerRef.current);
  //     touchCountRef.current = 0;
  //     handleDoubleClick(rowInfo);
  //   }
  // };

  return (
    <div id="CardWardQueue">
      {!props.errorMessage?.[CARD_QUEUE_WARD]?.error?.action?.message?.includes(
        "ไม่สามารถรับเข้าได้"
      ) ? (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_QUEUE_WARD}`, null);
            props.setProp(`successMessage.${CARD_QUEUE_WARD}`, null);
          }}
          error={props.errorMessage?.[CARD_QUEUE_WARD]?.error}
          success={props.successMessage?.[CARD_QUEUE_WARD]}
          languageUX={props.languageUX}
        />
      ) : (
        <ModConfirm
          openModal={!!props.errorMessage?.[CARD_QUEUE_WARD]?.error?.action?.message}
          titleName={intl.formatMessage({ id: "รับเข้าไม่สำเร็จ" })}
          titleColor="red"
          size="mini"
          approveButtonColor="red"
          approveButtonText={intl.formatMessage({ id: "ตกลง" })}
          content={props.errorMessage?.[CARD_QUEUE_WARD]?.error?.action?.message}
          onApprove={() => {
            let remainError = props.errorMessage;
            let { [CARD_QUEUE_WARD]: e, ...restError } = remainError || {};
            props.setProp("errorMessage", restError);
          }}
          denyButtonText={""}
        />
      )}

      <ModConfirm
        openModal={!!props.WardQueueSequence?.moveOut?.open}
        titleName={intl.formatMessage({ id: "ต้องการย้าย Ward ใช่หรือไม่" })}
        titleColor="blue"
        size="mini"
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        approveButtonColor="green"
        approveButtonText={intl.formatMessage({ id: "ตกลง" })}
        textContent={
          props.WardQueueSequence?.moveOut?.error ? (
            <ErrorMessage error={props.WardQueueSequence?.moveOut?.error} />
          ) : null
        }
        content={
          <>
            <Form>
              <Form.Field required>
                <label>{intl.formatMessage({ id: "การทำความสะอาด" })}</label>
                <Dropdown
                  selection
                  value={props.WardQueueSequence?.moveOut?.cleaningChoise}
                  onChange={(e, { value }) => {
                    props.setProp("WardQueueSequence.moveOut.cleaningChoise", value);
                  }}
                  options={props.masterOptions?.cleaningChoice || []}
                />
              </Form.Field>
            </Form>
          </>
        }
        onApprove={() => {
          props.runSequence({
            sequence: "WardQueue",
            action: "move_out",
            card: CARD_QUEUE_WARD,
            ...(barcodeRef?.current?.inputRef?.current?.value && {
              barcode: barcodeRef?.current?.inputRef?.current?.value,
            }),
          });
        }}
        onDeny={handleCloseMoveOut}
        onCloseWithDimmerClick={handleCloseMoveOut}
      />

      <CardWardQueueUX
        // options
        wardOptions={props.masterOptions.divisionIpd || []}
        patientStatusOptions={props.masterOptions.patientIPDStatus}
        // ref
        barcodeRef={barcodeRef}
        // data
        loadingFilter={props.WardQueueSequence?.filterQueue?.loading}
        ward={props.WardQueueSequence?.filterQueue?.division}
        patientStatus={props.WardQueueSequence?.filterQueue?.status}
        startDate={props.WardQueueSequence?.filterQueue?.start}
        endDate={props.WardQueueSequence?.filterQueue?.end}
        wardQueueList={renderWardQueueList}
        // config
        config={
          {
            // disabledAdmitButton: !getEnabledAction(
            //   ADMIT_ORDER_ROOM_ITEM_ACTION.WARD
            // ),
            // disabledBedMoveButton: !getEnabledAction(
            //   ADMIT_ORDER_ROOM_ITEM_ACTION.CHANGE_BED
            // ),
            // disabledCancelAdmitButton: !getEnabledAction(
            //   ADMIT_ORDER_ROOM_ITEM_ACTION.WARD
            // ),
            // hideBedMoveButton: getEnabledAction(
            //   ADMIT_ORDER_ROOM_ITEM_ACTION.TRANSFER
            // ),
            // showMoveOutButton: getEnabledAction(
            //   ADMIT_ORDER_ROOM_ITEM_ACTION.TRANSFER
            // ),
          }
        }
        // report
        showPrintReportBtn={true}
        disabledPrintReportBtn={!Array.isArray(props.masterOptions?.division)}
        onPrintReport={handlePrintReport}
        // callback
        // onCancelAdmit={handleCancelAdmit}
        onChangeWard={(_e: any, data: any) => {
          let wqs = {
            ...props.WardQueueSequence,
            filterQueue: {
              ...props.WardQueueSequence?.filterQueue,
              division: data.value,
            },
            // wardQueueList: [],
          };
          props.setProp("WardQueueSequence", wqs);
        }}
        onChangePatientStatus={(_e: any, data: any) => {
          let wqs = {
            ...props.WardQueueSequence,
            filterQueue: {
              ...props.WardQueueSequence?.filterQueue,
              status: data.value,
            },
            // wardQueueList: [],
          };
          props.setProp("WardQueueSequence", wqs);
        }}
        onChangeEndDate={(date: any) => {
          let wqs = {
            ...props.WardQueueSequence,
            filterQueue: {
              ...props.WardQueueSequence?.filterQueue,
              end: date,
            },
            // wardQueueList: [],
          };
          props.setProp("WardQueueSequence", wqs);
          // props.setProp("WardQueueSequence.filterQueue.end", date);
        }}
        onBarcodeKeyDown={handleBarcodeKeyDown}
        onSearch={handleSearch}
        // onAdmitReceive={handleAdmitReceive}
        // onBedMove={handleOpenModBedMove}
        moveOutDisable={
          !(
            props.WardQueueSequence?.selectedAdmitRoomItem?.room_item_status === 7 ||
            (props.django?.user?.role_types || []).includes("REGISTERED_NURSE")
          )
        }
        onMoveOut={() => {
          props.setProp("WardQueueSequence.moveOut.open", true);
        }}
        getSelectedRow={(_state: any, rowInfo: any, _column: any, _instance: any) => {
          return {
            style: {
              backgroundColor:
                rowInfo?.original?.id &&
                rowInfo?.original?.id === props.WardQueueSequence.selectedAdmitRoomItem?.id
                  ? "#cccccc"
                  : "white",
            },
            onClick: () => handleSelectRow(rowInfo),
            // onTouchStart: () => handleTouchStart(rowInfo),
            // onDoubleClick: () => handleDoubleClick(rowInfo),
          };
        }}
        // component
        roomNumberSearch={
          <SearchBox
            type="Room"
            verbose={true}
            style={{ width: "100%" }}
            fluid={true}
            disabled={props.WardQueueSequence?.filterQueue?.loading}
            placeholder={intl.formatMessage({ id: "ค้นจากเลขห้อง" })}
            toDisplay={(item: any) => item.room_no}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.WardQueueSequence?.filterQueue?.roomNO}
            setSelectedItem={(item: any) => {
              let wqs = {
                ...props.WardQueueSequence,
                filterQueue: {
                  ...props.WardQueueSequence?.filterQueue,
                  roomNO: item,
                },
                // wardQueueList: [],
              };
              props.setProp("WardQueueSequence", wqs);
              // props.setProp("WardQueueSequence.filterQueue.roomNO", item);
            }}
          />
        }
        doctorSearch={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Doctor"
            id="primary_doctor"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            upward={true}
            inline
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.WardQueueSequence?.filterQueue?.doctor}
            setSelectedItem={(item: any) => {
              let wqs = {
                ...props.WardQueueSequence,
                filterQueue: {
                  ...props.WardQueueSequence?.filterQueue,
                  doctor: item,
                },
              };
              props.setProp("WardQueueSequence", wqs);
            }}
          />
        }
        startDateElement={
          <DateTextBox
            value={props.WardQueueSequence?.filterQueue?.start}
            fluid={true}
            disabled={props.loadingFilter}
            style={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            onChange={handleChangeStartDate}
          />
        }
        languageUX={props.languageUX}
      />

      <Modal
        open={modAddCoPhysician?.open || false}
        size="mini"
        closeOnDimmerClick
        onClose={handleCloseModAddCoPhysician}
      >
        <Modal.Header style={{ background: "#5dbcd2" }}>แพทย์ผู้ร่วมรักษา</Modal.Header>
        <Modal.Content>
          <CardAddCoPhysician
            runSequence={props.runSequence}
            setProp={props.setProp}
            onEvent={props.onEvent}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            languageUX={props.languageUX}
            AddCoPhysicianSequence={props.AddCoPhysicianSequence}
            // function
            onClose={handleCloseModAddCoPhysician}
            onRefreshAndClose={handleRefreshCloseModAddCoPhysician}
            // data
            searchedItemListWithKey={props.searchedItemListWithKey}
            encounter_id={modAddCoPhysician.encounter?.id}
            primary_doctor={modAddCoPhysician.primary_doctor}
            co_physician={modAddCoPhysician.co_physician}
          />
        </Modal.Content>
      </Modal>

      <Modal
        open={props.modAdmit?.open || false}
        size="large"
        style={{ maxHeight: "95vh", overflow: "auto" }}
        closeOnDimmerClick
        onClose={handleCloseModAdmit}
      >
        <CardIntraHospitalTransfer
          // callback
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          intraTransferForm={props.intraTransferForm}
          intraHospitalTransferOptions={props.intraHospitalTransferOptions}
          searchedItemList={props.searchedItemList}
          errorMessage={props.errorMessage}
          loadingStatus={props.loadingStatus}
          masterOptions={props.masterOptions}
          selectVitalSignLoading={props.selectVitalSignLoading}
          selectedEncounter={props.WardQueueSequence?.selectedAdmitEncounter}
          getEncounterVitalSignListErr={props.getEncounterVitalSignListErr}
          encounterVitalSignList={props.encounterVitalSignList}
          WardQueueSequence={props.WardQueueSequence}
          patientId={props.WardQueueSequence?.selectedAdmitRoomItem?.encounter?.patient || null}
          hideAdmit={false}
          languageUX={props.languageUX}
          // errorMessageWard={props.errorMessage?.[CARD_QUEUE_WARD]}
        />
      </Modal>

      <ModCancelAdmitOrder
        open={!!openConfirm}
        title={openConfirm?.title}
        errorMessage={props.errorMessage}
        successMessage={props.successMessage}
        card={CARD_RESERVE_BED}
        // config
        type={openConfirm?.type}
        // callback
        onApprove={handleConfirm}
        onDeny={handleCloseModConfirm}
        languageUX={props.languageUX}
      />

      <Modal open={openModBedMove} closeOnDimmerClick onClose={handleCloseModBedMove}>
        <CardAdmitChange
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          //  seq
          runSequence={props.runSequence}
          AdmitChangeSequence={props.AdmitChangeSequence}
          // data
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemList={props.searchedItemList}
          action="CHANGE_BED"
          selectedHN={selectedHN}
          errorMessage={props.errorMessage}
          roomNo={props.SetBedSequence?.selectedRoom?.room_no}
          // options
          masterOptions={props.masterOptions}
          // callback
          getRoomList={handleGetRoomList}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModInfo
        open={props.errorMessage?.[`${CARD_QUEUE_WARD}_barcode`] === "NOT_FOUND"}
        titleColor={"red"}
        onApprove={handleCloseModNotFound}
        onClose={handleCloseModNotFound}
      >
        <div style={{ padding: "1rem 0", fontWeight: "bold" }}>
          <div>{intl.formatMessage({ id: "ไม่พบข้อมูล" })}</div>
        </div>
      </ModInfo>
    </div>
  );
};

export default React.memo(CardWardQueue);
